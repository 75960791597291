//
// FEO COOKIES BAR AND WINDOWS CONTROLLER
//

class feoCookies {
	bar: Element[]
	window: Element[]

	acceptAllButtons:    Element[]
	rejectAllButtons:    Element[]
	saveSettingsButtons: Element[]

	openWindowButtons:  Element[]
	closeWindowButtons: Element[]

	debug: boolean
	logSettings: boolean

	cookies: Object


	constructor(){
		this.debug = false
		this.logSettings = false
	}


	createWrapperElement(){
		const bar = document.getElementById("js:cookies:barInitWrapper");
		if(bar){
			if(this.debug || this.logSettings){
				console.log("feoCookies.ts - createWrapper - ALREADY FOUND, SKIPPING", bar)
			}

			return;
		}

		const body    = document.getElementsByTagName("body")[0];
		const wrapper = document.createElement("div");

		if(wrapper){
			wrapper.setAttribute("id","js:cookies:barInitWrapper")
			body.appendChild(wrapper);
		}	
	}


	// FIND ALL ELEMENTS AND ADD EVENTS TO THEM
	init(){
		this.bar    = Array.from(document.getElementsByClassName("js:cookies:bar"))
		this.window = Array.from(document.getElementsByClassName("js:cookies:window"))

		this.acceptAllButtons    = Array.from(document.getElementsByClassName("js:cookies:acceptAll"))
		this.rejectAllButtons    = Array.from(document.getElementsByClassName("js:cookies:rejectAll"))
		this.saveSettingsButtons = Array.from(document.getElementsByClassName("js:cookies:saveSettings"))

		this.openWindowButtons  = Array.from(document.getElementsByClassName("js:cookies:openWindow"))
		this.closeWindowButtons = Array.from(document.getElementsByClassName("js:cookies:closeWindow"))


		// DONT CONTINUE OF EITHER BAR OR WINDOW ARE NOT FOUND
		if(!this.bar || !this.window){
			console.error("Cookies bar or window not found, ending initialization")
			return
		}

		// INIT WINDOW OPEN BUTTONS
		for (const b of this.openWindowButtons){
			if(b.classList.contains("js:initialized")){
				continue
			}

			b.addEventListener("click", () => {
				this.openWindow()
			})

			b.classList.add("js:initialized")
		}

		// INIT WINDOW CLOSE BUTTONS
		for (const b of this.closeWindowButtons){
			if(b.classList.contains("js:initialized")){
				continue
			}

			b.addEventListener("click", () => {
				this.closeWindow()
			})

			b.classList.add("js:initialized")
		}

		// INIT ACCEPT ALL BUTTONS
		for (const b of this.acceptAllButtons){
			if(b.classList.contains("js:initialized")){
				continue
			}

			b.addEventListener("click", () => {
				this.setAllActive()
				this.updateSettings()
				this.closeWindow()
			})

			b.classList.add("js:initialized")
		}


		// INIT REJECT ALL ALL BUTTONS
		for (const b of this.rejectAllButtons){
			if(b.classList.contains("js:initialized")){
				continue
			}

			b.addEventListener("click", () => {
				this.setAllReject()
				this.updateSettings()
				this.closeWindow()
			})

			b.classList.add("js:initialized")
		}


		// INIT SAVE SETTINGS BUTTONS
		for (const b of this.saveSettingsButtons){
			if(b.classList.contains("js:initialized")){
				continue
			}

			b.addEventListener("click", () => {
				this.updateSettings()
				this.closeWindow()
			})

			b.classList.add("js:initialized")
		}

		// SHOW COOKIE BAR AFTER EVERYTHING IS READY
		this.showBar();

		if(this.debug){
			console.log("feoCookies.ts - initialization")
			console.log("bar:",    this.bar)
			console.log("window:", this.window)
			console.log("openWindowButtons:",   this.openWindowButtons)
			console.log("closeWindowButtons:",  this.closeWindowButtons)
			console.log("acceptAllButtons:",    this.acceptAllButtons)
			console.log("saveSettingsButtons:", this.saveSettingsButtons)
		}
	}

	setCookies(data: Object): Boolean {
		this.cookies = data;

		if(this.debug || this.logSettings){
			console.log("feoCookies.ts - cookies set to new values", this.cookies)
		}

		return true;
	}

	getCookies(): Object {
		return this.cookies;
	}

	getCookie(name: string): Object {
		if(this.cookies[name]){
			return this.cookies[name]
		}
	}

	// LOADS UP COOKIE BAR AFTER JS IS READY
	showBar(){
		for (const bar of this.bar){
			bar.classList.add("_loaded")

			this.dispatchEvent("feoCookiesBarDisplayed");

			if(this.debug){
				console.log("feoCookies.ts - window opened")
			}
		}
	}


	// OPENS SETTINGS WINDOW
	openWindow(){
		for (const window of this.window){
			window.classList.add("_opened")

			if(this.debug){
				console.log("feoCookies.ts - window opened")
			}
		}

		this.dispatchEvent("feoCookiesWindowOpened");
	}

	// CLOSES SETTINGS WINDOW
	closeWindow(){
		for (const window of this.window){
			window.classList.remove("_opened")

			if(this.debug){
				console.log("feoCookies.ts - window closed")
			}
		}

		this.dispatchEvent("feoCookiesWindowClosed");
	}

	// SETS ALL SETTINGS CHECKBOXES AS "CHECKED"
	setAllActive(){
		const checkboxes = document.querySelectorAll(".js\\:cookies\\:checkbox")
		for (const check of checkboxes as any){
			check.checked = true
		}

		this.dispatchEvent("feoCookiesAcceptedAll");

		if(this.debug){
			console.log("feoCookies.ts - all cookies set as active");
		}
	}

	// SETS ALL SETTINGS CHECKBOXES AS "UN-CHECKED"
	setAllReject(){
		const checkboxes = document.querySelectorAll(".js\\:cookies\\:checkbox")
		for (const check of checkboxes as any){
			check.checked = false
		}

		this.dispatchEvent("feoCookiesRejectedAll");

		if(this.debug){
			console.log("feoCookies.ts - all cookies set as rejected");
		}
	}

	// LOADS INITIAL COOKIE BAR CONTENT
	firstLoad(url: string){
		if(this.debug){
			console.log("feoCookies.ts - firstLoad() - " + url);
		}

		if(!url){
			return;
		}
	
		var request = new XMLHttpRequest();
		request.open("GET", url);
		request.onload = () => {
			if (request.status == 200) {
				if(this.debug){
					console.log("feoCookies.ts - firstLoad() - AJAX request finished");
				}

				// REPLACE COOKIES BAR CONTENT WITH RETURNED DATA
				const cookieBarInitWrapper = document.querySelector("#js\\:cookies\\:barInitWrapper");
				cookieBarInitWrapper.innerHTML = request.response;

				// cookieBarWrapper.innerHTML = '<div style="margin:10px;padding:10px;border:5px dashed red;font-size:22px">cookie bar content reloaded</div>';

				// INIT BAR AND WINDOW
				this.init()

				// TRIGGER CUSTOM EVENT, JUST IN CASE SOME OTHER JS NEEDS TO WATCH FOR CHANGED
				this.dispatchEvent("feoCookiesFirstLoad");
			}
			else {
				if(this.debug){
					console.error("feoCookies.ts - firstLoad() - AJAX request failed", request.status);
				}
			}
		};

		request.send();
	}

	// SAVE SETTINGS (SENDS AJAX REQUEST WITH FORM DATA FROM POPUP)
	updateSettings(): Boolean {
		const form: HTMLFormElement = document.querySelector("#js\\:cookies\\:form")

		if(form){
			const requestURL  = form.getAttribute("action") // TARGET URL FOR AJAX REQUEST
			const requestType = form.getAttribute("method") // "POST" OR "GET"
			const data        = new FormData(form)          // CURRENT FORM DATA


			var request = new XMLHttpRequest();
			request.open(requestType, requestURL);
			request.onload  = () => {
				if (request.status == 200) {
					if(this.debug){
						console.log("feoCookies.ts - AJAX request finished");
					}

					// REPLACE COOKIES BAR CONTENT WITH RETURNED DATA
					const cookieBarWrapper = document.querySelector("#js\\:cookies\\:barInitWrapper");
					if(request.response){
						const parsed = JSON.parse(request.response)

						this.setCookies(parsed.consents);

						//eval(html.content);
						if(parsed.content){
							cookieBarWrapper.innerHTML = parsed.content;
						}
						else {
							cookieBarWrapper.innerHTML = null;
						}
						
					}
					else {
						cookieBarWrapper.innerHTML = null;
					}

					// cookieBarWrapper.innerHTML = '<div style="margin:10px;padding:10px;border:5px dashed red;font-size:22px">cookie bar content reloaded</div>';
				
					// REINIT IN CASE WE GET NEW WINDOW AS RESPONSE
					this.init()

					// TRIGGER CUSTOM EVENT, JUST IN CASE SOME OTHER JS NEEDS TO WATCH FOR CHANGED
					this.dispatchEvent("feoCookiesSettingsUpdated");
					return true;
				}
				else {
					if(this.debug){
						console.error("feoCookies.ts - AJAX request failed", request.status);
					}
					return false;
				}
			};

			request.send(data);
			return true;
		}
		else {
			return false;
		}
	}

	dispatchEvent(name: string){
		var event = new CustomEvent(name, {
			detail: {
				feoCookies: this
			}
		});
		window.dispatchEvent(event)
	}
}


// @ts-ignore
window.feoCookiesClass = new feoCookies()